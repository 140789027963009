const colors = {
  main: "#230f2a",
  mainLight: "#d3bed8",
  secondary: "#e72a53",
  secondaryLight: "#fad8df",
  secondaryDark: "#ae203f",
  secondaryRgb: "211, 190, 216",
  gray: "#d7d3d8",
  white: "#faf8fb",
}

const theme = {
  colors,
}

export default theme
